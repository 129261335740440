
// Libraries
import * as React from 'react'
import {useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

// Service
import BlogService from '../services/blog'

// Components
import Button from './button'
import BlogSearch from './blogSearch'
import BlogPagination from './blogPagination'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/pro-regular-svg-icons'
import DonateCta from './donateCta'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export default function BlogList({ searchQuery, pageContext = {}, data }) {
	const query = data

	// Initialise search query state
	const [blogSearchQuery, setBlogSearchQuery] = useState("")

	// Search component callback
	const handleSearch = value => {
		// Set search query state
		setBlogSearchQuery(value)
	}

	//Initial search blogs
	const initialBlogPosts = query?.filtered?.nodes

	const [customBlogSearchPaginationIndex, setCustomBlogSearchPaginationIndex] = useState(false);
	const [blogPaginationItems, setBlogPaginationItems] = useState(false);

	// Check whether to use initial blog posts or searched blog posts
	let blogPosts = !blogSearchQuery ? initialBlogPosts : blogPaginationItems?.items
	const { currentPage = 1, numPages = 1 } = pageContext
	const isFirst = currentPage === 1
	const isLast = currentPage === numPages
	const prevPage = currentPage - 1 === 1 ? '/blog/' : '/blog/'+(currentPage - 1).toString()
	const nextPage = '/blog/'+(currentPage + 1).toString()
	const blogTotalCount = query?.all?.nodes ? query.all.nodes.length : 0

	const [isSearching, setIsSearching] = useState(false)

	// Initialise new instance of the blog posts service
	const blogService = new BlogService()

	useEffect(() => {
		if (blogSearchQuery !== ""){
			setIsSearching(true);
			Promise.all([
				getPaginationBlogItems(blogSearchQuery, customBlogSearchPaginationIndex, setIsSearching)
				// .catch(() => console.log('There was an error fetching the blog posts.', 'error'))
			]).then(() => {
				setBlogPaginationItems({
					emptyMessage: "No blog posts found, try adjusting your search criteria."
				})
			})
		}
	}, [blogSearchQuery, customBlogSearchPaginationIndex]);

	function getPaginationBlogItems(query, page, setIsSearching){
		let queryParams = [];
		queryParams['paginate'] = 1
		queryParams['search'] = query
		queryParams['perPage'] = 6
		if (page) {
			queryParams['page'] = page
		}
		if (!queryParams['filter']['status_id']) {
			queryParams['filter']['status_id'] = "1"
		}

		const response = blogService.list(queryParams).then( (response) => {
			setBlogPaginationItems({
				items: response.data.data,
				pagination: response.data
			})
			setIsSearching(false)
		}).catch(err => console.error(err))

		if(response?.data){
			console.log("blog pagination response", response)
		}
	}

	const blogItems = blogPaginationItems?.items;
	const blogItemsTotal = blogPaginationItems?.pagination?.total;
	const blogItemsTo = blogPaginationItems?.pagination?.to;
	const blogItemsIsLastPage = blogPaginationItems?.pagination?.last_page === blogPaginationItems?.pagination?.current_page;
	const blogItemsCurrentPage = blogPaginationItems?.pagination?.current_page;
	const blogItemsIsFirstPage = blogItemsCurrentPage === 1;

	function prevClickHandler(index){
		if(index > 0){
			setCustomBlogSearchPaginationIndex(index);
		}
	}

	function nextClickHandler(index){
		setCustomBlogSearchPaginationIndex(index);
	}

	function pageClickHandler(index){
		setCustomBlogSearchPaginationIndex(index);
	}

	const paginationVars = {
		slug: '/blog/',
		limit: blogSearchQuery ? blogPaginationItems.pagination?.per_page : 6,
		totalCount: blogSearchQuery ? blogItemsTo : blogTotalCount,
		isFirst: blogSearchQuery ? blogItemsIsFirstPage : isFirst,
		isLast: blogSearchQuery ? blogItemsIsLastPage : isLast,
		prevPage: blogSearchQuery ? blogItemsCurrentPage - 1 : prevPage,
		nextPage: blogSearchQuery ? blogItemsCurrentPage + 1 : nextPage,
		numPages: blogSearchQuery ? Math.ceil(blogItemsTotal / 6) : numPages,
		currentPage: blogSearchQuery ? blogPaginationItems.pagination?.current_page : currentPage,
		searchBlogActive: blogSearchQuery,
		searchBlogLocal: "",
		searchBlogPosts: blogSearchQuery ? blogItems : [],
		searchBlogPostTotalCount: blogSearchQuery ? blogItems ? blogItemsTotal : 0 : 0,
		searchBlogPaginatedItems: blogItems,
		prevClickHandler: prevClickHandler,
		nextClickHandler: nextClickHandler,
		pageClickHandler: pageClickHandler
	}

	const isBrowser = typeof window !== "undefined"
	if (isBrowser) {
		paginationVars.searchBlogLocal = localStorage.getItem('blog_search_query') ?
			localStorage.getItem('blog_search_query') : ""
	}

	return (
		<>
			<div className="blog-list-wrapper">
				<div className="blog-header container-fluid">
					<div className="blog-header__title"></div>
					<div className="container">
						<div className="blog-header__search">
							<h1 className="blog-search__heading">Blog</h1>
							<BlogSearch onSearch={_.debounce(handleSearch, 500)} />
						</div>
						{blogPosts && blogPosts.length > 0 && (
							<div className="blog-header__pagination">
								<BlogPagination pagination={paginationVars} />
							</div>
						)}
					</div>
				</div>
				<div className="blog-list">
					<div className="container">
						{isSearching
							? <div className="spinner-container">
								<div className="spinner">
									<FontAwesomeIcon icon={faSpinner} title="Loading indicator icon"/>
								</div>
							</div>
							: <ul>
								{blogPosts && blogPosts.map((blog) =>
									<li className="blog" itemScope itemType="https://schema.org/Blog">
										<div className="inner">
											<Link className="inner_link" to={`/blog/${blog?.slug}`} />
											<div className="effect">
												<div className="effect-inner">
													<div className="title-wrapper">
														<div className="blog__title" itemProp="title">
															<span>{blog?.title}</span>
														</div>
													</div>
													<div className="blog__summary" itemProp="summary">
														<span>{blog?.summary}</span>
													</div>
													<div className="blog__cta">
														<div className="see-blog">
															<Button to={`/blog/${blog?.slug}`}
																	aria-label={`Read more about ${blog?.title}`}>
																Read More
															</Button>
														</div>
													</div>
												</div>
											</div>
											<div className="blog__image">
												<div className="gradient"></div>
												{blog.image_gatsby
													? <GatsbyImage image={blog.image_gatsby.childImageSharp.gatsbyImageData}
																   alt={blog.title}
																   itemProp="image" />
													: blog.image && <div className={`gatsby-image-wrapper gatsby-image-wrapper-constrained`}>
														<div style="max-width: 686px; display: block;">
															<img alt=""
																 role="presentation"
																 aria-hidden="true"
																 src="data:image/svg+xml;charset=utf-8,%3Csvg height='450.1875' width='686' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
																 style="max-width: 100%; display: block; position: static;" />
														</div>
														<div aria-hidden="true" data-placeholder-image=""
															 style="opacity: 0; transition: opacity 500ms linear 0s; background-color: rgb(8, 88, 88); position: absolute; inset: 0px; object-fit: cover;"></div>
														<picture>
															<source srcSet={blog.image} />
															<img itemProp="image" width="686" height="450"
																 data-main-image="" decoding="async"
																 alt={blog.title}
																 style="object-fit: cover; opacity: 1;" />
														</picture>
														<img src={blog.image} alt={blog.title} itemProp="image" />
													</div>
												}
											</div>
										</div>
										<span itemProp="blogStatus" content="https://schema.org/BlogScheduled" />
										<span itemProp="blogAttendanceMode"
											  content="https://schema.org/OfflineBlogAttendanceMode" />
										<span itemProp="description" content={blog?.synopsis} />
										<span itemProp="endDate" content={moment(blog?.ends).toISOString()} />
										<span itemProp="organizer" itemScope itemType="https://schema.org/Organization">
											<span itemProp="name" content={blog?.venue?.name} />
											<span itemProp="url"
												  content={blog?.venue?.website_url || blog?.venue_website_url} />
										</span>
									</li>
								)}
								{(!blogPosts || !blogPosts.length) &&
									<h3>No blog posts
										found {searchQuery && `using the search term "${searchQuery}"`}</h3>
								}
							</ul>
						}
					</div>
				</div>
				{blogPosts && blogPosts.length > 0 && (
					<div className="blog-footer container">
						<BlogPagination pagination={paginationVars} />
					</div>
				)}
			</div>
			<DonateCta />
		</>
	)
}
