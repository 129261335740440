
// Libraries
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch  } from '@fortawesome/pro-regular-svg-icons'
import { navigate } from 'gatsby'
import { useState, useEffect } from 'react'

export default function BlogSearch({ onSearch }) {

	let storageSearchQuery = ""
	const isBrowser = typeof window !== "undefined"

	// This runs when the page is loaded.
	useEffect(() => {
		if (isBrowser) {
			storageSearchQuery = localStorage.getItem('blog_search_query') ? localStorage.getItem('blog_search_query') : ""

			if (storageSearchQuery !== ""){
				setSearchQuery(storageSearchQuery)
				onSearch(storageSearchQuery)
			}
		}
	}, [])
	
	// Initialise search query state
	const [searchQuery, setSearchQuery] = useState()

	const handleChange = (event) => {

		const value = event?.target?.value
		// Set search query state
		setSearchQuery(value)
		onSearch(value)

		if (isBrowser) {
			if (value !== "") {
				localStorage.setItem('blog_search_query', value)
			}
			else {
				localStorage.removeItem('blog_search_query')
			}
		}
	}

	const handleSubmit = blog => {
		blog.prblogDefault()

		// Navigate user to search page with the current search query
		return navigate('/blog/search', {
			state: {searchQuery: searchQuery || ''}
		})
	}

	return (
		<form onSubmit={handleSubmit} className="blog-search">
			<div className="container">
				<div className="blog-search-wrap">
					<input type="text" placeholder="Search Blog posts" className="blog-search-wrap__input"
						   onChange={handleChange} value={searchQuery} />
					<button className="blog-search-wrap__submit" type="submit">
						<FontAwesomeIcon icon={faSearch} title="Search"/>
					</button>
				</div>
			</div>
		</form>
	)
}
