
// Libraries
import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import BlogList from '../components/blogList'

const BlogIndexTemplate = ({ data, pageContext }) => {
	return (
		<Layout className="nav-blue-all">
			<Seo title="Blogs" />
			<BlogList data={data} pageContext={pageContext} />
		</Layout>
	)
}

// Query GraphQL for blog list & featured blog
export const pageQuery = graphql`
	query blogListOldQuery($skip: Int!, $limit: Int!) {
		filtered: allBlog(
			sort: {fields: publish_at, order: ASC}
			filter: {status_id: {eq: 2}}
			limit: $limit
			skip: $skip
		) {
			nodes {
				apiId
				id
				slug
				status_id
				title
				summary
				content
				image
				image_gatsby {
					childImageSharp {
						gatsbyImageData(
							width: 686,
							height: 450,
							layout: CONSTRAINED,
						)
					}
				}
				publish_at
				created_by
				updated_by
				created_at
				updated_at
			}
		}
		all: allBlog(
			sort: {fields: publish_at, order: ASC}
			filter: {status_id: {eq: 2}}
		) {
			nodes {
				apiId
				id
				slug
				status_id
				title
				summary
				content
				image
				image_gatsby {
					childImageSharp {
						gatsbyImageData(
							width: 1800,
							height: 1181,
							layout: CONSTRAINED,
						)
					}
				}
				publish_at
				created_by
				updated_by
				created_at
				updated_at
			}
		}
	}
`

export default BlogIndexTemplate
