// Libraries
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import * as React from 'react'

const BlogPagination = ({pagination}) => {

		function disableClick(e) {
			e.preventDefault()
		}

		const paginationTotalCount = pagination.searchBlogActive ? pagination.searchBlogPostTotalCount : pagination.totalCount

		var totalItemsCount = pagination.totalCount
		var numberOfItemsPerPage = pagination.limit
		var page = pagination.currentPage

		var start = (page * numberOfItemsPerPage) - (numberOfItemsPerPage - 1)
		var end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount)

		return (
			<>
				<div className="pagination-wrapper">
					<div className="pagination-results">
						<span className={`${paginationTotalCount === 1 ? "hidden" : ""}`}><strong>{start}</strong> to </span><strong>{end}</strong> result{paginationTotalCount === 1 ? "" : "s"} of <strong>{paginationTotalCount}</strong>
					</div>
					<div className={`blog-pagination`}>
						{pagination.numPages > 1 && <>
							<Link className="pagination-pn pagination-previous"
								  to={pagination.searchBlogActive ? "#" : pagination.prevPage}
								  rel="prev"
								  disabled={pagination.isFirst} onClick={(e) => {
								if(pagination.isFirst) {
									disableClick(e)
								}
								if(pagination.searchBlogActive){
									pagination.prevClickHandler(pagination.prevPage)
								}
							}}>
								<FontAwesomeIcon icon={faChevronLeft}/> Prev
							</Link>
							<div className="pagination-numbers">
								{Array.from({ length: pagination.numPages }, (_, i) => (
									<div
										className="pagination-number"
										key={`pagination-number${i + 1}`}
									>
										<Link
											to={`${pagination.slug}${i === 0 ? '' : i + 1}`}
											disabled={pagination.isFirst && pagination.isLast}
											className={`pagination-entry ${i + 1 === pagination.currentPage ? 'active' : ''}`}
											onClick={(e) => {
												if(pagination.searchBlogActive){
													disableClick(e)
													pagination.pageClickHandler(i + 1);
												}
											}}
										>
											{i + 1}
										</Link>
									</div>
								))}
							</div>
							<Link className="pagination-pn pagination-next"
								  to={pagination.searchBlogActive ? "#" : pagination.nextPage}
								  rel="next"
								  disabled={pagination.isLast}
								  onClick={(e) => {
									  if(pagination.isLast) {
										  disableClick(e)
									  }
									  if(pagination.searchBlogActive){
										  disableClick(e)
										  pagination.nextClickHandler(pagination.nextPage)
									  }
								  }}>
								Next <FontAwesomeIcon icon={faChevronRight}/>
							</Link>
						</>}
					</div>
				</div>
			</>
		)
}

export default BlogPagination
